import React, { useCallback, useState } from "react";

export function useMenu() {
  const [anchor, setAnchor] = useState(null);

  const onOpenMenu = useCallback((event: React.MouseEvent<unknown>) => {
    event?.stopPropagation();
    setAnchor(event.currentTarget);
  }, []);

  const onCloseMenu = useCallback((event: React.MouseEvent<unknown>) => {
    event?.stopPropagation();
    setAnchor(null);
  }, []);

  const toggle = useCallback(
    (event: React.MouseEvent<unknown>) => {
      event?.stopPropagation();

      if (anchor) {
        setAnchor(null);
      } else {
        setAnchor(event.currentTarget);
      }
    },
    [anchor]
  );

  return { anchor, open: Boolean(anchor), onOpenMenu, onCloseMenu, toggle };
}
