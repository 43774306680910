import { Button } from "@material-ui/core";
import React from "react";
import { useLanguage } from "../hooks/useLanguage";

interface ILanguageSwitcherProps {
  onClick?: () => void;
}

export function LanguageSwitcher({ onClick }: ILanguageSwitcherProps) {
  const { onLanguageChange, language } = useLanguage();

  return (
    <Button color="inherit" onClick={onClick || onLanguageChange}>
      {language === "fr" ? "EN" : "FR"}
    </Button>
  );
}
