import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faSignOutAlt,
  faCog,
  faQuestionCircle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Tooltip,
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  BoxProps,
  MenuItem,
  Menu,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@mui/material/Hidden";

import { useTranslation } from "next-i18next";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useLanguage } from "../../hooks/useLanguage";
import { useMenu } from "../../hooks/useMenu";
import { selectPageHeader } from "../../store/selectors";
import { gaEvent } from "../../utils/ga";
import { LanguageSwitcher } from "../LanguageSwitcher";
import Link from "../Link";
import TooltipBody from "../TooltipBody";

const useStyles = makeStyles((theme) => ({
  "@global": {
    html: {
      height: "100%",
    },
    "pre, code": {
      fontFamily: "monospace",
    },
    ".uppy-DragDrop-browse": {
      color: theme.palette.primary.main,
    },
    ".uppy-DragDrop-label": {
      ...theme.typography.body1,
    },
    main: {
      scrollMarginTop: "72px",
    },
  },
  titleWrapper: {
    flexGrow: 1,
    display: "flex",
  },
  title: {
    color: "#000",
  },
  titleLink: {
    display: "inline-flex",
    textDecoration: "none",
    alignItems: "center",
  },
  appBar: {
    zIndex: theme.zIndex.modal - 2,
    borderBottom: "1px solid #D4D7DE",
  },
  menuButton: {
    marginRight: theme.spacing(0.5),
  },
  drawerPaper: {
    width: 260,
  },
  logo: {
    height: "28px",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  text: {
    color: theme.palette.common.black,
    paddingLeft: theme.spacing(2),
    fontWeight: 500,
  },
}));

// errors/snackbar not used here, but this is also used as global namespaces
const namespaces = ["common", "errors", "snackbar"];

export default function Page({
  children,
  containerWidth = "xl",
  disableMargin = false,
  ...props
}: React.PropsWithChildren<
  {
    containerWidth?: "md" | "lg" | "xl" | false;
    disableMargin?: boolean;
  } & BoxProps
>) {
  const classes = useStyles();
  const { t } = useTranslation(namespaces);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((mobileOpen) => !mobileOpen);
  }, []);

  const { onLanguageChange, language } = useLanguage();

  const header = useSelector(selectPageHeader);

  const menu = useMenu();

  const onClickTitle = useCallback(() => {
    gaEvent({
      category: "Navigation",
      action: "Click Title",
      label: "Click Title In Navigation",
    });
  }, []);

  const onClickHelpCenter = useCallback(() => {
    gaEvent({
      category: "Navigation",
      action: "Click Help Center",
      label: "Click Help Center In Navigation",
    });
  }, []);

  const onClickContactSupport = useCallback(
    (event: React.MouseEvent<unknown>) => {
      try {
        /* eslint-disable @typescript-eslint/no-explicit-any*/
        (window as any).Intercom("show");

        menu.onCloseMenu(event);
        if (mobileOpen) {
          handleDrawerToggle();
        }
      } catch (error) {
        console.log(error);
      }
    },
    [menu, mobileOpen]
  );

  const onClickLogout = useCallback(() => {
    gaEvent({
      category: "Navigation",
      action: "Click Logout",
      label: "Click Logout In Navigation",
    });
  }, []);

  const onClickSettings = useCallback(() => {
    gaEvent({
      category: "Navigation",
      action: "Click Settings",
      label: "Click Settings In Navigation",
    });
  }, []);

  const onClickLogoutMobile = useCallback(() => {
    handleDrawerToggle();
    gaEvent({
      category: "Mobile Navigation",
      action: "Click Logout",
      label: "Click Logout In Mobile Navigation",
    });
  }, [handleDrawerToggle]);

  const onClickSettingsMobile = useCallback(() => {
    handleDrawerToggle();
    gaEvent({
      category: "Mobile Navigation",
      action: "Click Settings",
      label: "Click Settings In Mobile Navigation",
    });
  }, [handleDrawerToggle]);

  const onClickHelpCenterMobile = useCallback(() => {
    handleDrawerToggle();
    gaEvent({
      category: "Mobile Navigation",
      action: "Click Help Center",
      label: "Click Help Center In Mobile Navigation",
    });
  }, [handleDrawerToggle]);

  const onClickHomeMobile = useCallback(() => {
    handleDrawerToggle();
    gaEvent({
      category: "Mobile Navigation",
      action: "Click Home",
      label: "Click Home In Mobile Navigation",
    });
  }, [handleDrawerToggle]);

  const onClickOpenDrawer = useCallback(() => {
    if (!mobileOpen) {
      gaEvent({
        category: "Mobile Navigation",
        action: "Open",
        label: "Open Mobile Navigation",
      });
    } else {
      gaEvent({
        category: "Mobile Navigation",
        action: "Close",
        label: "Close Mobile Navigation",
      });
    }

    handleDrawerToggle();
  }, [handleDrawerToggle, mobileOpen]);

  const onClickCloseDrawer = useCallback(() => {
    handleDrawerToggle();
    gaEvent({
      category: "Mobile Navigation",
      action: "Close Outside",
      label: "Close Outside Mobile Navigation",
    });
  }, [handleDrawerToggle]);

  const drawer = (
    <List disablePadding>
      <ListItem
        button
        component={Link}
        naked
        href="/"
        onClick={onClickHomeMobile}
      >
        <ListItemText>{t("common:home")}</ListItemText>
      </ListItem>
      <ListItem
        button
        component={Link}
        naked
        href="/settings"
        onClick={onClickSettingsMobile}
      >
        <ListItemText>{t("common:settings")}</ListItemText>
      </ListItem>
      <ListItem
        button
        component="a"
        target="_blank"
        href={t("common:helpCenterLink")}
        onClick={onClickHelpCenterMobile}
      >
        <ListItemText>{t("common:helpCenter")}</ListItemText>
      </ListItem>
      <ListItem button onClick={onClickContactSupport}>
        <ListItemText>{t("common:contactSupport")}</ListItemText>
      </ListItem>
      <ListItem
        button
        component={Link}
        naked
        href="/logout"
        onClick={onClickLogoutMobile}
      >
        <ListItemText>{t("common:logout")}</ListItemText>
      </ListItem>
      <ListItem button onClick={onLanguageChange}>
        <ListItemText>
          <Box component="span" fontWeight="500">
            {language === "fr" ? "EN" : "FR"}
          </Box>
        </ListItemText>
      </ListItem>
    </List>
  );

  const sidemenu = (
    <Hidden xsDown implementation="css">
      <Tooltip
        title={
          <TooltipBody>
            <Typography>{t("common:changeLanguage")}</Typography>
          </TooltipBody>
        }
      >
        <span>
          <LanguageSwitcher />
        </span>
      </Tooltip>
      <Box marginLeft={1} component="span" />
      <Tooltip
        title={
          <TooltipBody>
            <Typography>{t("common:settings")}</Typography>
          </TooltipBody>
        }
      >
        <IconButton
          color="inherit"
          component={Link}
          naked
          href="/settings"
          onClick={onClickSettings}
        >
          <FontAwesomeIcon icon={faCog as IconProp} />
        </IconButton>
      </Tooltip>
      <Box marginLeft={1} component="span" />
      <>
        <IconButton
          color="inherit"
          onClick={menu.open ? menu.onCloseMenu : menu.onOpenMenu}
        >
          <FontAwesomeIcon icon={faQuestionCircle as IconProp} />
        </IconButton>
        <Menu
          anchorEl={menu.anchor}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={menu.open}
          onClose={menu.onCloseMenu}
        >
          <MenuItem
            component="a"
            target="_blank"
            href={t("common:helpCenterLink")}
            onClick={onClickHelpCenter}
          >
            <Typography> {t("common:helpCenter")}</Typography>
          </MenuItem>

          <MenuItem onClick={onClickContactSupport}>
            <Typography> {t("common:contactSupport")}</Typography>
          </MenuItem>
        </Menu>
      </>
      <Box marginLeft={1} component="span" />
      <Tooltip
        title={
          <TooltipBody>
            <Typography>{t("common:logout")}</Typography>
          </TooltipBody>
        }
      >
        <IconButton
          color="inherit"
          component={Link}
          naked
          href="/logout"
          onClick={onClickLogout}
          data-qa="logout-cta"
        >
          <FontAwesomeIcon icon={faSignOutAlt as IconProp} />
        </IconButton>
      </Tooltip>
    </Hidden>
  );

  return (
    <Box {...props}>
      <Box display="block" displayPrint="none">
        <AppBar
          position="static"
          color="secondary"
          className={classes.appBar}
          elevation={0}
        >
          <Container maxWidth={containerWidth}>
            <Toolbar disableGutters>
              <Hidden smUp implementation="css">
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  edge="start"
                  onClick={onClickOpenDrawer}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <div className={classes.titleWrapper}>
                <Link
                  naked
                  href="/"
                  onClick={onClickTitle}
                  className={classes.titleLink}
                >
                  <img
                    src={header?.logo || "/static/images/logo.svg"}
                    className={classes.logo}
                  />

                  {header && (
                    <Typography data-new-version="true" data-qa="orgName-text" className={classes.text}>
                      {header.text}
                    </Typography>
                  )}
                </Link>
              </div>
              {sidemenu}
            </Toolbar>
          </Container>
        </AppBar>

        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={onClickCloseDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      {!containerWidth ? (
        children
      ) : (
        <Container maxWidth={containerWidth} id="content" component="main">
          <Box mb={disableMargin ? 0 : 6}>{children}</Box>
        </Container>
      )}
    </Box>
  );
}

Page.namespaces = [...namespaces];
