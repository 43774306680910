import { makeStyles, Card } from "@material-ui/core";
import { CardProps } from "@material-ui/core/Card/Card";
import React from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
  },
}));

export default function TooltipBody(props: CardProps) {
  const classes = useStyles();

  return <Card className={classes.card} {...props} />;
}
