import { OrganizationTier } from "@prisma/client";
import { RootState } from "./index";

export interface IPageHeader {
  logo: string;
  text: string;
}

export function selectPageHeader(state: RootState): IPageHeader | undefined {
  if (state.meeting.meeting) {
    return {
      logo: state.meeting.meeting.organization.avatar,
      text:
        state.meeting.meeting.organization.slogan ||
        state.meeting.meeting.organization.name,
    };
  }

  if (state.user.session) {
    return {
      logo:
        state.user.session.user.organization.tier ===
        OrganizationTier.enterprise
          ? state.user.session.user.organization.avatar?.path
          : undefined,
      text:
        state.user.session.user.organization.slogan ||
        state.user.session.user.organization.name,
    };
  }
}
