import { CircularProgress, makeStyles } from "@material-ui/core";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import SEO, { ISEOProps } from "../components/SEO";
import { useLanguage } from "../hooks/useLanguage";
import { selectHasChecked } from "../store/modules/auth/selectors";
import { selectHasSession } from "../store/modules/user/selectors";

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(8),
  },
}));

type ComponentProps = Record<string, unknown>;

export default function withAuth(
  Component: React.ElementType,
  seoProps?: ISEOProps
) {
  function WithAuth(props: ComponentProps) {
    const classes = useStyles({});

    const { language } = useLanguage();
    const router = useRouter();

    const isSignedIn = useSelector(selectHasSession);
    const hasChecked = useSelector(selectHasChecked);

    useEffect(() => {
      if (hasChecked && isSignedIn && process.browser) {
        const intercomId = "pk3s5o53";

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.intercomSettings = {
          app_id: intercomId,
          hide_default_launcher: true,
          horizontal_padding: 16,
          vertical_padding: 86,
          language_override: language,
        };

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `https://widget.intercom.io/widget/${intercomId}`;

        document.body.appendChild(script);
      }

      if (hasChecked && !isSignedIn && process.browser) {
        router.push("/login");
      }
    }, [isSignedIn, hasChecked]);

    if (!process.browser || !hasChecked) {
      return (
        <div className={classes.loadingWrapper}>
          {seoProps && <SEO {...seoProps} />}
          <CircularProgress />
        </div>
      );
    }

    if (!isSignedIn) {
      return null;
    }

    return <Component {...props} />;
  }

  return WithAuth;
}
